import PouchDB from 'pouchdb';
import {IPago, IPrestamo} from "../interfaces/IPrestamo";
import {calPrestamo} from "../helper";
let db = new PouchDB('loanCal');

export function updateAjustes(obj:object) {
    return db.put({...obj,_id: "ajustes"});
}
export function getAjustes() {
    return db.get('ajustes')
}
export function guardarPrestamo(obj:IPrestamo) {
    if(obj._rev){
        return db.put(obj);
    }
    return db.post({...obj,_id: "pre-"+new Date().toISOString()});
}
export function getPrestamos() {
    // return db.find({
    //     selector: {_id: 'ajustes'},
    //     fields: ['_id', 'name'],
    //     sort: ['name']
    // })
    return db.allDocs({ include_docs: true, attachments: true,startkey: "pre-"})
}
export function getPrestamoById(id:string) {
    return db.get(id)
}
export function delPrestamo(doc:any) {
    return db.remove(doc)
}