import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonGrid,
    IonCol,
    IonRow,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonDatetime, useIonViewWillLeave
} from '@ionic/react';
import './detalles.scss';
import {calPrestamo, formatDate, formatPeso} from "../../helper";
import Strings from '../../assets/strings';
import {IPago, IPrestamo, IPrestamoCal} from "../../interfaces/IPrestamo";
import {guardarPrestamo} from "../../services/localDB";

const initialState ={
    monto: 0,
    fecha: formatDate(new Date(),"YYYY-MM-DD"),
    cuotas:1,
    cuotasArray:[1],
}
const AddPago = ({pre, onUpdate, onClose}:{pre:IPrestamoCal,onUpdate:Function,onClose:Function }) => {
    const [state, setState] = useState(initialState);
    const [cuotasOptions, setCuotasOptions] = useState<{nCuotas:number,monto:number}[]>([]);
    const handleChange = (e:any) => {
        const {name, value,type} = e.target;
        setState({...state,[name]: (type === "number")? parseFloat(value):value})
    };

    useEffect(()=>{
        let select = [];
        let subAmort = [...(pre.amort ?? [])].slice(pre.cuotasPagadas);
        for(let i = 0;i < pre.cuotas - pre.cuotasPagadas;i++){
            let acumulado = 0;
            for(let m = 0;m <= i;m++){

                //console.log(subAmort[m]?.totalaPagar)
                acumulado += subAmort[m]?.totalaPagar;
            }
            select.push({
                //key:amort[i]?.totalaPagar,
                monto:acumulado,
                nCuotas: i + 1});
        }
        setCuotasOptions(select);
    },[pre,setCuotasOptions, setState]);
    useEffect(()=>{
        if(cuotasOptions.length){
            let monto = cuotasOptions.find((c)=>c.nCuotas === state.cuotas)?.monto ?? 0;
            let cuotasArray = [];
            for (let i=pre.cuotasPagadas+1; i <= pre.cuotasPagadas+ +state.cuotas; i++){
                cuotasArray.push(i)
            }
            return  setState({...state,cuotasArray, monto})
        }
    },[pre,cuotasOptions, state.cuotas]);

    const guardar = () =>{
        let pago:IPago = {
            cuotas:state.cuotasArray,
            fecha: state.fecha,
            monto: state.monto,
            montoRestante:pre.montoRestante,
            proximoPago:pre.proximoPago
        }


      let prestamo:IPrestamo = {
          monto: +pre.monto,
          interes: +pre.interes,
          cuotas: +pre.cuotas,
          ciclosPago: +pre.ciclosPago,
          tipoAmortizacion: +pre.tipoAmortizacion,
          fecha: pre.fecha,
          cliente:pre.cliente,
          telefono:pre.telefono,
          direccion:pre.direccion,
          pagos:pre.pagos,
          _id:pre._id,
          _rev:pre._rev,
      }
        if(prestamo.pagos)
            prestamo.pagos.unshift(pago);
        else
            prestamo.pagos = [pago];

        //para calcular montoRestante y proximoPago
        let posCal = calPrestamo(prestamo);
        pago.montoRestante = posCal.montoRestante;
        pago.proximoPago = posCal.proximoPago;

        //prestamo.pagos.pop();
        //prestamo.pagos.unshift(pago);
        //-------------------

        guardarPrestamo(prestamo).then((res)=>{
            onUpdate({cliente:posCal.cliente,preId:posCal._id,...pago})
        }).finally(()=> onClose())
    }

  return (
      <div className={"iconPay show"}>
          <div className={"inputs"}>
              <IonGrid>
                  <IonRow>
                      <IonCol>
                          <IonItem>
                              <IonLabel position="floating">{Strings.cuotas}</IonLabel>
                              <IonSelect interface="popover" value={state.cuotas} name={"cuotas"} onIonChange={handleChange}>
                                  {cuotasOptions.map((c)=>
                                      <IonSelectOption key={c.nCuotas} value={c.nCuotas} >{c.nCuotas} cuota</IonSelectOption>
                                  )}
                              </IonSelect>
                          </IonItem>
                      </IonCol>
                      <IonCol>
                          <IonItem>
                              <IonLabel position="floating">{Strings.fecha}</IonLabel>
                              <IonDatetime displayFormat="MM/DD/YYYY" style={{paddingBottom: 5}}
                                           min={"2020-01-01"}
                                           max="2026-12-12"
                                           value={formatDate(state.fecha,"MM-DD-YYYY")}
                                           name={"fecha"}  onIonChange={handleChange}
                                           cancelText={Strings.cancelar}
                                           doneText={"Ok"}
                              />
                          </IonItem>
                      </IonCol>
                  </IonRow>
                  <IonRow>
                      <IonCol>
                          <div className={"monto"}>{formatPeso(state.monto)}</div>
                      </IonCol>
                  </IonRow>
              </IonGrid>
          </div>
          <IonButton color="primary" onClick={guardar}>
              {Strings.guardar}
          </IonButton>
          <IonButton color="secondary" fill={"clear"} onClick={()=>onClose()}>{Strings.cancelar}</IonButton>

      </div>
  );
};

export default AddPago;
