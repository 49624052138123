import React, {useState} from 'react';
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLoading, useIonViewWillEnter
} from '@ionic/react';
import {getPrestamos} from "../../services/localDB";
import './prestamos.scss';
import {calPrestamo, formatDate, formatPeso} from "../../helper";
import {useHistory} from "react-router-dom";
import Strings from '../../assets/strings';
import {IPrestamoCal} from "../../interfaces/IPrestamo";

const Page: React.FC = () => {
    const {push}=useHistory();
    const [prestamos, setPrestamos] = useState<IPrestamoCal[]>();
    const [showLoading, setShowLoading] = useState(false);
    useIonViewWillEnter(()=>{
        setShowLoading(true)
        getPrestamos().then((res:any)=>{
             let pres = res.rows.map((p:any)=>calPrestamo(p.doc,true));
             setPrestamos(pres)
        }).finally(()=> setShowLoading(false))
    })
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{Strings.prestamos}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
          <IonLoading isOpen={showLoading} message={Strings.cargando}/>
          <div className="listPre">
              {prestamos?.map((item:IPrestamoCal)=>
              <div className={"item "+(item.cuotasPendientes?"p":"")} key={item._id} onClick={()=>push("/prestamoDetalle/"+item._id)}>
                      <div className={"row"}>
                          <div className="name">{item.cliente}</div>
                          {/*<div className="cuota"> {formatPeso(item.montoProximoPago)}</div>*/}
                      </div>
                      <div className={"row"}>
                          <div className="cuota">Cuotas pagadas: {item.cuotasPagadas}/{item.cuotas}</div>
                          <div className="name">Proximo pago: {formatDate(item.proximoPago)}</div>
                      </div>
                      <div className={"row"}>
                          <div className="name">Cuotas pendiente: {item.cuotasPendientes}</div>

                          <div className="cuota">Balance pendiente: {formatPeso(item.balancePeniente)}</div>

                      </div>
              </div>
              )}
          </div>
      </IonContent>
    </IonPage>
  );
};

export default Page;
