const CICLOPAGOS = [
    {key:1,value:"diario"},
    {key:7,value:"semanal"},
    {key:14,value:"bisemanal"},
    {key:15,value:"quincenal"},
    // {key:"16",value:"15 y fin de mes"},
    {key:30,value:"mensual"},
    {key:365,value:"anual"}
]
const TIPOAMORT = [
    {key:1,value:"cuotaFija"},
    {key:2,value:"disminuirCuota"},
    {key:3,value:"interesFijo"},
    {key:4,value:"capitalAlFinal"},
]


export {CICLOPAGOS,TIPOAMORT}