import React, {useEffect, useState} from 'react';
import {IPago} from "../interfaces/IPrestamo";
import { BluetoothSerial } from '@ionic-native/bluetooth-serial';

import jsPDF from "jspdf";

import {
    IonButton,
    IonContent,
    IonButtons,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    useIonToast,
    isPlatform
} from "@ionic/react";
import {getAjustes} from "../services/localDB";
import {IAjustes} from "../interfaces/IAjustes";
import {formatDate, formatPeso} from "../helper";
import Strings from '../assets/strings';
import { settingsOutline } from 'ionicons/icons';

import EscPosEncoder from "esc-pos-encoder-ionic";
interface IBT {
    "name":string,
    "address":string,
    "id":string,
    "class":number
}
//
// interface IBTios {
//     "name":string,
//     "uuid":string,
//     "id":string,
//     "rssi":number
// }

export default function Recibo({pago,close}:{pago:IPago,close:Function}) {
    //const [showLoading, setShowLoading] = useState(false);
    const [listBluetoothDevice, setListBluetoothDevice] = useState<IBT[]>();
    const [viewConf, setViewConf] = useState(false);

    const [presentToast] = useIonToast();

    const [empresa, setEmpresa] = useState<IAjustes>();
    const generatePdf = ()=>{
        let docWidth = 136.063;
        let doc = new jsPDF('p', 'pt', [docWidth,210]);

        doc.setFont("Helvetica");
        doc.setFontSize(8)
        if (empresa?.empresa){
            doc.text( empresa.empresa+"\nTel:"+empresa.telefono+"\nDireccion:"+empresa.direccion,0,10, )
        }
        doc.text( "RECIBO DE PAGO",docWidth/2,50, {align:"center"} )

        doc.text("NO.: 4654654\nFecha:"+formatDate(pago.fecha),0,70, )
        doc.setLineDashPattern ([0,1], 80);

        doc.setLineDashPattern([1,1],40);
        doc.line(0, 87, docWidth, 87);

        doc.text("RECIBIMOS DE: "+pago.cliente+"\nLA SUMA DE: "+formatPeso(pago.monto),0,101, )


        doc.text("CONCEPTO: Pago cuota/s "+pago.cuotas.join(),0,120, )

        doc.setLineDashPattern([1,1],40);
        doc.line(0, 132, docWidth, 132);

        doc.setFontSize(7)
        doc.text("MONTO RESTANTE: "+formatPeso(pago.montoRestante),0,145, )
        doc.text("PROXIMO PAGO: "+formatDate(pago.proximoPago),0,155, )


        doc.setLineDashPattern([0,0],0);
        doc.line(10, 180, docWidth-10, 180);
        doc.setFontSize(8)
        doc.text("FIRMA AUTORIZADA ",docWidth/2,190,{align:"center"} )
        doc.autoPrint()
        doc.output('dataurlnewwindow');

    }
    const print = ()=>{
        if(!isPlatform("ios") && !isPlatform("android") ) return generatePdf();
        BluetoothSerial.isConnected()
            .then(r => {
                const encoder = new EscPosEncoder();
                const result = encoder.initialize();

                if (empresa?.empresa){
                    result
                        .text(empresa.empresa)
                        .newline()
                }

                if(empresa?.telefono || empresa?.celular){
                    let tel = "Tel: ";
                    if(empresa.telefono){
                        tel += empresa.telefono;
                        if(empresa.celular) tel += " / "+empresa.celular;
                    }else{
                        tel += empresa.celular;
                    }
                    result
                        .size('small')
                        .line(tel)
                }
                if(empresa?.rnc){
                    result
                        .size('small')
                        .line(Strings.rnc+" "+empresa.rnc)
                }
                if(empresa?.direccion){
                    result
                        .size('small')
                        .text(Strings.direccion+": "+empresa.direccion)
                        .newline()
                }
                result
                    .newline()
                    .align('center')
                    .line( "RECIBO DE PAGO" )
                    .newline()
                    .align('left')
                    .line("NO.: 4654654\nFecha: "+formatDate(pago.fecha))
                    .newline()
                    .text("RECIBIMOS DE: "+pago.cliente+"\nLA SUMA DE: "+formatPeso(pago.monto))
                    .newline()
                    .line("CONCEPTO: Pago cuota/s "+pago.cuotas.join())
                    .newline()
                    .line("MONTO RESTANTE: "+formatPeso(pago.montoRestante))
                    .line("PROXIMO PAGO: "+formatDate(pago.proximoPago))
                    .newline()
                    .newline()
                    .align('center')
                    .line("_____________________________")
                    .align('center')
                    .line("FIRMA AUTORIZADA ")
                    .newline()
                    .newline()
                    .newline()
                    .cut();

                const resultByte = result.encode();
                BluetoothSerial.write(resultByte).then(()=>{}, (e)=>{
                    presentToast({
                        message: "Error",
                        duration:2000
                    })
                });
            })
            .catch(error => {
                setViewConf(true);
                let printerId = localStorage.getItem("printerId");
                if(printerId){
                    connectBT(printerId);
                }
            });
    }
    const connectBT = (id:string)=>{
            BluetoothSerial.connect(id).subscribe(()=>{
                presentToast({
                    message: 'Conectado con exito!',
                    duration:2000
                })
                setViewConf(false);
                if(localStorage.getItem("printerId")){
                    print();
                }else {
                    localStorage.setItem('printerId', id);
                }


            },(error)=>{
                presentToast({
                    message: 'Error al conectar',
                    duration:2000
                })
            });

    }
    useEffect(()=>{
        getAjustes().then((data:any) =>{
            setEmpresa(data)
        });
    },[setEmpresa]);
    useEffect(()=>{
        if(viewConf){
            BluetoothSerial.list().then((res)=>{
                setListBluetoothDevice(res);
            })
        }
    },[viewConf]);
    return (
        <>
            <IonContent>
                {viewConf?(
                    <IonList>
                        {listBluetoothDevice?.map((item)=>
                            <IonItem button onClick={()=>connectBT(item.address)}>
                                <IonLabel>{item.name}</IonLabel>
                            </IonItem>
                        )}
                    </IonList>
                ):(
                    <div id="invoice-POS">
                        <div id="top">
                            <h2>{empresa?.empresa}</h2>
                            <div>
                                tel : {empresa?.celular}
                            </div>
                            <div>
                                {Strings.direccion} : {empresa?.direccion}
                            </div>
                        </div>
                        <h4>RECIBO DE PAGO</h4>
                        <div>
                            <div>NO.: {465465}</div>
                            <div>{Strings.fecha.toUpperCase()}: {formatDate(pago.fecha)}</div>
                        </div>
                        <div>
                            <hr/>
                            <div>RECIBIMOS DE: {pago.cliente}</div>
                            <div>LA SUMA DE: {formatPeso(pago.monto)}</div>
                            <div>CONCEPTO: pago cuota/s {pago.cuotas.join()}</div>
                            <hr/>
                            <div>MONTO RESTANTE: {formatPeso(pago.montoRestante)}</div>
                            <div>PROXIMO PAGO: {formatDate(pago.proximoPago)}</div>
                        </div>
                    </div>
                )}
            </IonContent>
            <IonButtons>
                <IonButton onClick={()=>close()}>Cerrar</IonButton>
                {!viewConf && <IonButton onClick={print}>Imprimir</IonButton>}
                {(isPlatform("ios") || isPlatform("android")) &&
                    <IonButton style={{marginLeft:"auto"}} onClick={()=>setViewConf(!viewConf)}>
                        <IonIcon icon={settingsOutline} />
                    </IonButton>
                }
            </IonButtons>
        </>
    );
};