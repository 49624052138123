import {IonApp, IonRouterOutlet, IonSplitPane, isPlatform, useIonAlert} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import React, {useEffect} from "react";
import {
  Plugins,
  Capacitor,
  PushNotificationToken,
  PushNotification,
  PushNotificationActionPerformed
} from "@capacitor/core";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';



import Menu from './components/Menu';
import Calculadora from './pages/Calculadora';
import Prestamos from './pages/prestamos/Prestamos';
import Pagos from './pages/pagos';

import PrestamoDetalles from './pages/prestamos/PrestamoDetalles';

import Ajustes from './pages/Ajustes';
import About from './pages/About';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Theme custome */
import './theme/mainStyle.scss';

import Strings from './assets/strings';


const { PushNotifications } = Plugins;


const openUrl = (url:string)=>{
  window.open(url,url.indexOf("http") !== -1 ? "_system":"")
}
const App: React.FC = () => {
  const [present] = useIonAlert();
  let page = window.location.href;

  useEffect(()=>{
    {
      isPlatform("ios") || isPlatform("android") &&
      FirebaseAnalytics.logEvent('page_view', {page:"loanCal"})
          .then((res: any) => console.log(res))
          .catch((error: any) => console.error(error));
    }
  },[page])
  useEffect(() => {
    // Register with Apple / Google to receive push via APNS/FCM
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then( result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token: PushNotificationToken) => {
          console.log('Push registration success, token: ' + token.value);
        }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error))
          alert('Error on registration: ' + JSON.stringify(error));
        }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification:PushNotification) => {
      let body = notification.body;
      let url = notification.data?.link;
      present({
        header: notification.title,
        message: body,
        ...(url?{
          buttons: [
            'Cancel',
            { text: Strings.abrir,
              handler: ()=> openUrl(url)},
          ],
        }:{})
      })
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (val: PushNotificationActionPerformed) => {
          let url = val.notification.data?.link;
          if(url) openUrl(url);
        }
    );
    return ()=>{
      PushNotifications.removeAllListeners();
    }
  }, []);
  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        if (window.location.pathname === "/calculadora") {
          Plugins.App.exitApp();
        }
      });
    }
  }, []);

  return (
      <IonApp>
        <IonReactRouter >
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route path="/" exact={true}>
                <Redirect to="/calculadora" />
              </Route>
              <Route path="/Calculadora" exact={true} component={Calculadora}/>
              <Route path="/prestamos" exact={true} component={Prestamos}/>
              <Route path="/pagos" exact={true} component={Pagos}/>
              <Route path="/prestamoDetalle/:preId" exact={true} component={PrestamoDetalles}/>
              <Route path="/Ajustes" exact={true} component={Ajustes}/>
              <Route path="/about" exact={true} component={About}/>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>
  );
};

export default App;
