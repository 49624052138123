import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
    IonButtons,
    IonMenuButton,
    isPlatform} from '@ionic/react';
import React from 'react';
const About: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
            <IonButtons slot="start">
                <IonMenuButton />
            </IonButtons>
          <IonTitle>LoanCal</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
          <div style={{padding:15,textAlign:"justify"}}>
              <p><b>LoanCal</b> es una herramienta creada por <a href="https://dsegura.net" target={"_blank"}>“dsegura.net”</a> esta versión se limita solo a calcular las cuotas de tus préstamos y generar tabla de amortización. También contamos con otra herramienta más profesional llamada <b>“pretamitapp”</b> que te permite automatizar todo el proceso de préstamos.</p>
              {!isPlatform("ios") &&
                 <p>Para mas información visita nuestra web: <a href="https://prestamistapp.com/" target={"_blank"}>prestamistapp</a></p>
              }
          </div>
      </IonContent>
    </IonPage>
  );
};

export default About;
