import React, {useState} from 'react';
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,IonModal,IonButton,
    IonLoading, useIonViewWillEnter
} from '@ionic/react';
import './pagos.scss';

import {getPrestamos} from "../../services/localDB";
import {formatDate, formatPeso} from "../../helper";
import Strings from '../../assets/strings';
import {IPago} from "../../interfaces/IPrestamo";
import Recibo from "../../components/recibo";

const PagosPage: React.FC = () => {
    const [printRecibo, setPrintRecibo] = useState<IPago>();

    const [pagos, setPagos] = useState<IPago[]>();
    const [showLoading, setShowLoading] = useState(false);
    useIonViewWillEnter(()=>{
        setShowLoading(true)
        getPrestamos().then((res:any)=>{
            let pagos:IPago[] = [];
            res.rows.forEach((e:any)=>{
                let pago = e.doc.pagos?.map((p:any)=>
                    ({preId:e.id,cliente:e.doc.cliente,...p})
                );
                if(pago?.length)
                pagos.push(...pago);
            })
            setPagos(pagos)
        }).finally(()=> setShowLoading(false))
    });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{Strings.pagos}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
          {printRecibo &&
          <IonModal isOpen={true} onDidDismiss={()=>setPrintRecibo(undefined)}>
                <Recibo pago={printRecibo} close={()=>setPrintRecibo(undefined)}/>
          </IonModal>
          }


          <IonLoading isOpen={showLoading} message={Strings.cargando}/>
          <div className="listPagos">
              <table>
                  <thead>
                  <tr>
                      <th>{Strings.cliente}</th>
                      <th>{Strings.fecha}</th>
                      <th>{Strings.monto}</th>
                  </tr>
                  </thead>
                  <tbody>
                      {pagos?.map(((p,i)=>
                          <tr key={i} onClick={()=>setPrintRecibo(p)}>
                              <td>{p.cliente}</td>
                              <td>{formatDate(p.fecha)}</td>
                              <td>{formatPeso(p.monto)}</td>
                          </tr>
                      ))}
                  </tbody>
              </table>
          </div>
      </IonContent>
    </IonPage>
  );
};

export default PagosPage;
