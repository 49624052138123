
import moment from 'moment';
import Strings from "../assets/strings";
import {IAmort} from "../interfaces/IAmort";
import {IPrestamo, IPrestamoCal} from "../interfaces/IPrestamo";

export function getLSLang(tipo:string) {
    const langLS = localStorage.getItem('lang');
    const browserLang = navigator.language.split('-')[0];
    let defaultLang = "es";
    let defaultCountry = "";

    //si el idioma del navegador es soportado sera el idioma por defecto
    if(Strings.getAvailableLanguages().includes(browserLang)) defaultLang = browserLang;
    //valor por defecto por si no esta guardado
    var response = tipo?(tipo === "l" ? defaultLang :defaultCountry):defaultLang+"-"+defaultCountry;


    if(langLS && langLS.split("-").length === 2){
        switch (tipo) {
            case "l":
                response = langLS.split("-")[0];
                break;
            case "c":
                response = langLS.split("-")[1];
                break;
            default:
                response = langLS;
                break;
        }
    }else{
        //si no esta setearlo
        localStorage.setItem("lang",defaultLang+"-"+defaultCountry)
    }
    return response;
}
export function setLSLang(language?:string,country?:string) {
    const langLS = localStorage.getItem('lang');
    let lang = "es";
    let count = "";

    //set language
    if(language) {
        lang = language;
    }else if(langLS && langLS.split("-").length === 2){
        lang = langLS.split("-")[0];
    }
    //set country
    if(country) {
        count = country;
    }else if(language && langLS && langLS.split("-").length === 2){
        count = langLS.split("-")[1];
    }

    localStorage.setItem("lang", lang+"-"+count)
}
export function formatDate(date:any, format="DD/MM/YYYY") { //h:mm a
    if(!date)
        return "";

    return moment(date).format(format);

}
export function formatPeso(val:number,maxFraction = 2) {
    let value = val ? +val: 0;
    let countryCode = "";
    const lSCountryCode = getLSLang("c");
    if (lSCountryCode) countryCode = lSCountryCode;

    const paises = [
        {paiCodigo: "DO",paiModeneda: "DOP", paiIdioma:"es"},
        {paiCodigo: "US",paiModeneda: "USA", paiIdioma:"en"},
        {paiCodigo: "ES",paiModeneda: "EUR", paiIdioma:"es"},
        {paiCodigo: "BO",paiModeneda: "BOB", paiIdioma:"es"},
        {paiCodigo: "CO",paiModeneda: "COP", paiIdioma:"es"},
        {paiCodigo: "CR",paiModeneda: "CRC", paiIdioma:"es"},
        {paiCodigo: "PY",paiModeneda: "PYG", paiIdioma:"es"},
        {paiCodigo: "GT",paiModeneda: "GTQ", paiIdioma:"es"},
        {paiCodigo: "VE",paiModeneda: "VES", paiIdioma:"es"},
        {paiCodigo: "PE",paiModeneda: "PEN", paiIdioma:"es"},
        {paiCodigo: "HN",paiModeneda: "HNL", paiIdioma:"es"},
        {paiCodigo: "NI",paiModeneda: "NIO", paiIdioma:"es"},
        {paiCodigo: "PA",paiModeneda: "PAB", paiIdioma:"es"},
        {paiCodigo: "MX",paiModeneda: "MXN", paiIdioma:"es"},
        {paiCodigo: "AR",paiModeneda: "ARS", paiIdioma:"es"},
        {paiCodigo: "CL",paiModeneda: "CLP", paiIdioma:"es"},
        {paiCodigo: "UY",paiModeneda: "UYU", paiIdioma:"es"}
    ];
    let monedaDisponible = paises.find(p=>p.paiCodigo === countryCode.toUpperCase());
    //si no se encuentra la moneda poner una universal
    if(!monedaDisponible){
        return '$' + value
            .toFixed(maxFraction)
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }

    //  if (countryCode && typeof Intl == 'object' && typeof Intl.NumberFormat == 'function' && monedaDisponible){
    return value.toLocaleString(monedaDisponible.paiIdioma+"-"+monedaDisponible.paiCodigo, {
        style: 'currency',
        currency: monedaDisponible.paiModeneda,
        minimumFractionDigits: 0,
        maximumFractionDigits: maxFraction,
    });
    // }


}
function sumarQuincena(date:any,restar?:any) {
    let fecha = moment(date);
    if(!fecha.isValid()) return null;

    let ultimoDiaMes = fecha.daysInMonth();

    if (fecha.date() !== 15 && fecha.date() !== ultimoDiaMes){
        //si la fecha no es correcta retorna la siguiente fecha correcta
        if(fecha.date() > 15)
            fecha.set("date",ultimoDiaMes);
        else
            fecha.set("date",15);

        return new Date(fecha.year(), fecha.month(), fecha.date());
    }
    if (fecha.date() === 15) {
        if(restar){
            let prevMonth = fecha.add(-1,"month")
            return new Date(fecha.year(), prevMonth.month(), prevMonth.daysInMonth())
        }else{
            return new Date(fecha.year(), fecha.month(), ultimoDiaMes)
        }
    }else{
        if(restar){
            return new Date(fecha.year(), fecha.month(), 15)
        }else{
            let nextFecha = fecha.add(15,"days");
            return new Date(nextFecha.year(), nextFecha.month(), nextFecha.date())
        }
    }
}
function PMT(capital:number ,interes:number ,cuotas:number ){
    var rate = interes / 100;
    var denominator = Math.pow((1 + rate), cuotas) - 1;
    return (rate + (rate / denominator)) * capital;
}
//monto:any,intere:any,cuotas:any,ciclosPago:any,tipoAmortizacian:any,fecha?:any
export function buildAmort(input:IPrestamo):IAmort[]{
    const propCiclosPago = input.ciclosPago;
    let prestamo = {
        fecha: moment(input.fecha || new Date()),
        capitalPrestamo: input.monto,
        interes: input.interes,
        ciclosPago: propCiclosPago === 16 ? 15: propCiclosPago,
        cuotas: input.cuotas,
        tipoAmortizacion: input.tipoAmortizacion,
    };
    ///quitar una cuota ya que se envia la fecha del primer pago
    if(propCiclosPago === 30){ //si es mensual agrega un mes
        prestamo.fecha = prestamo.fecha.add(-1, 'month');
    }else if(propCiclosPago === 16){
        prestamo.fecha = moment(sumarQuincena(prestamo.fecha.format("YYYY-MM-DD"),true));
    }else{
        prestamo.fecha = prestamo.fecha.add(-prestamo.ciclosPago, 'days');
    }

    let capitalApagar;
    let interes;
    let pago;

    let balanceRestante = prestamo.capitalPrestamo;
    //var cuotas = this.cuotas();

    let amortizacion:IAmort[] = [];

    let fechaCuota = prestamo.fecha;

    for (let i = 1; i <= prestamo.cuotas; i++)
    {
        if(propCiclosPago === 30){ //si es mensual agrega un mes
            fechaCuota = fechaCuota.add(1, 'month');
        }else if(propCiclosPago === 16){
            fechaCuota = moment(sumarQuincena(fechaCuota.format("YYYY-MM-DD")));
        }else{
            fechaCuota = fechaCuota.add(prestamo.ciclosPago, 'days');
        }

        switch (prestamo.tipoAmortizacion) {
            case 2: //disminuis cuotas
                capitalApagar = prestamo.capitalPrestamo / prestamo.cuotas;
                interes = balanceRestante * prestamo.interes / 100;
                pago = capitalApagar + interes;
                break
            case 3://Interés fijo
                capitalApagar = prestamo.capitalPrestamo / prestamo.cuotas;
                interes = prestamo.capitalPrestamo * prestamo.interes / 100;
                pago = capitalApagar + interes;
                break
            case 4://capital al final
                capitalApagar = i === prestamo.cuotas ? prestamo.capitalPrestamo : 0; // CapitalPrestamo / model.cuotas;
                interes = prestamo.capitalPrestamo * prestamo.interes / 100;
                pago = capitalApagar + interes;
                break
            default://Cuotas Fijas
                interes = balanceRestante * prestamo.interes / 100;
                pago = PMT(prestamo.capitalPrestamo, prestamo.interes, prestamo.cuotas);
                pago = (pago * 100) / 100;
                capitalApagar = pago - interes;
        }

        amortizacion.push({
            cuota: i,
            fecha:fechaCuota.format("YYYY-MM-DD"),
            balanceRestante: balanceRestante,
            capitalApagar: capitalApagar,
            interes: interes,
            totalaPagar: pago,
            // fecha = (prestamo.Fecha).AddDays((int)prestamo.ModalidadPago * i)
        });

        balanceRestante = balanceRestante - capitalApagar;
    }

    return amortizacion;
}
export function getImgSize(imgData:string){
    return new Promise (function (resolved, rejected) {
        let i = new Image()
        i.onload = function(){
            resolved({w: i.width, h: i.height})
        };
        i.src = imgData
    })
}

export function calPrestamo(pre:IPrestamo,excludeAmort=false):IPrestamoCal {

    let amort = buildAmort(pre);
    let montoPagado = pre.pagos?.reduce((a, b) => a + b.monto, 0) ?? 0;
    let montoApagar = amort.reduce((a, b) => a + b.totalaPagar, 0) ?? 0;

    let cuotasPagadas = pre.pagos?.reduce((a, b) => a + b.cuotas.length, 0) ?? 0;
    let proximoPago = amort[cuotasPagadas+1]?.fecha ?? "";
    let montoProximoPago = amort[cuotasPagadas+1]?.totalaPagar ?? 0;


    let prestamo:IPrestamoCal ={
        ...pre,
        amort,
        montoApagar,
        montoPagado,
        montoRestante:montoApagar - montoPagado,
        montoProximoPago,
        cuotasPagadas,
        proximoPago,
        cuotasPendientes:0,
        balancePeniente:0,

    };

    let today = new Date().getTime();
    let cuotasPendientesObj = amort.filter((a)=>  new Date(a.fecha).getTime() >= new Date(proximoPago).getTime() && new Date(a.fecha).getTime() <= today)
    if(cuotasPendientesObj.length){
        prestamo.cuotasPendientes = cuotasPendientesObj.length;
        prestamo.balancePeniente = cuotasPendientesObj.reduce((a, b) => a + b.totalaPagar, 0) ?? 0;
    }



    if(excludeAmort){
        delete prestamo.amort;
        delete prestamo.pagos;
    }
    return prestamo;
}