import React, {useCallback, useEffect, useState} from 'react';
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonLoading,
    IonGrid,
    IonCol,
    IonRow,
    IonIcon, useIonAlert, useIonViewDidEnter, useIonViewWillLeave, IonModal
} from '@ionic/react';
import {delPrestamo, getPrestamoById, guardarPrestamo} from "../../services/localDB";
import './detalles.scss';
import {calPrestamo, formatDate, formatPeso} from "../../helper";
import Strings from '../../assets/strings';
import {close} from "ionicons/icons";
import TableAmort from "../../components/tableAmort";
import {IPago, IPrestamo, IPrestamoCal} from "../../interfaces/IPrestamo";
import {CICLOPAGOS, TIPOAMORT} from "../../helper/CONSTANTS";
import AddPago from "./AddPago";
import {useHistory,useParams} from "react-router-dom";
import Recibo from "../../components/recibo";




const PrestamoDetalles: React.FC = () => {
    const {preId} = useParams() as {preId: string;};
    const [showAlert] = useIonAlert();
    const history = useHistory();
    const [printRecibo, setPrintRecibo] = useState<IPago>();
    const [addPagoModal, setAddPagoModal] = useState(false);

    const [showLoading, setShowLoading] = useState(false);
    const [prestamo, setPrestamo] = useState<IPrestamo>();

    const [prestamoCal, setPrestamoCal] = useState<IPrestamoCal>();
    const [showAmort, setShowAmort] = useState<boolean>(false);

    const fetch = useCallback(
        () => {
            setShowLoading(true)
            getPrestamoById(preId).then((res:any)=>{
                setPrestamo(res);
                let pre = calPrestamo(res);
                if(pre) setPrestamoCal(pre);
            }).finally(()=> setShowLoading(false))
        },
        [setShowLoading,setPrestamoCal,setPrestamo,preId],
    );
    useEffect(()=>{
        fetch()
    },[preId,fetch])
    useIonViewWillLeave(()=>setAddPagoModal(false));

    const delLastPago = () =>{
        showAlert({
            message:Strings.formatString(Strings.confirmEliminar,Strings.pago.toString().toLowerCase()) as string,
            buttons: [
                'Cancel',
                { text: 'Ok', handler: (d) => {
                        setShowLoading(true)
                        getPrestamoById(preId).then((response)=>{
                            let res = response as IPrestamo;
                            let p = {...res};
                            if(p.pagos?.length) {
                                let pagos = p.pagos;
                                pagos.shift();
                                p.pagos = pagos;
                            }
                            guardarPrestamo(p).then(()=>{
                                fetch()
                            }).finally(()=> setShowLoading(false))
                        })
                    } },
            ],
        })
    }
    const eliminar = () =>{
        showAlert({
            message:Strings.formatString(Strings.confirmEliminar,Strings.prestamo.toString().toLowerCase()) as string,
            buttons: [
                'Cancel',
                { text: 'Ok', handler: (d) => {
                        setShowLoading(true)
                        delPrestamo(prestamo).then(()=>{
                            setShowLoading(false)
                            history.replace("/prestamos");
                        })
                    } },
            ],
        })
    }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
            <IonButtons slot="primary">
                <IonButton onClick={eliminar}>
                    {Strings.eliminar}
                </IonButton>
            </IonButtons>
          <IonTitle onClick={()=>alert("adasd")}>
              <div>{prestamoCal?.cliente}</div>
              <small>Cuotas pendiente: {prestamoCal?.cuotasPendientes}</small>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className={"prestamoDetalles"}>
          <IonLoading isOpen={showLoading} message={Strings.cargando}/>
          {!!prestamoCal && <>
              <IonGrid className={"info"}>
                  <IonRow>
                      <IonCol sizeXs={"4"} ><span>{Strings.montoPrestado}:</span> <span>{formatPeso(prestamoCal.monto)}</span></IonCol>
                      <IonCol sizeXs={"4"} ><span>{Strings.amortizacion}:</span>
                          <span>
                              {(Strings as any)[TIPOAMORT.find(e=>e.key === prestamoCal?.tipoAmortizacion)?.value ?? ""]}
                          </span>
                      </IonCol>
                      <IonCol sizeXs={"4"} ><span>{Strings.interes}:</span> <span>{prestamoCal.interes}</span></IonCol>
                      <IonCol sizeXs={"4"} ><span>{Strings.fechaInicio}:</span> <span>{formatDate(prestamoCal.fecha)}</span></IonCol>
                      <IonCol sizeXs={"4"} ><span>{Strings.modalidad}:</span>
                          <span>
                              {(Strings as any)[CICLOPAGOS.find(e=>e.key === prestamoCal?.ciclosPago)?.value ?? ""]}
                          </span>
                      </IonCol>
                      <IonCol sizeXs={"4"} ><span>{Strings.proximoPago}:</span> <span>{formatDate(prestamoCal.proximoPago)}</span></IonCol>
                  </IonRow>
                  <IonRow>
                      <IonCol sizeXs={"4"} ><span>{Strings.cuotasPagadas}:</span> <span>{prestamoCal.cuotasPagadas}  / {prestamoCal.cuotas}</span></IonCol>
                      <IonCol sizeXs={"4"} ><span>{Strings.montoPagado}:</span> <span>{formatPeso(prestamoCal.montoPagado)}</span></IonCol>
                      <IonCol sizeXs={"4"} ><span>{Strings.totalApagar}:</span> <span>{formatPeso(prestamoCal.montoApagar)}</span></IonCol>

                      <IonCol sizeXs={"4"} ><span>{Strings.balancePendiente}:</span> <span>{formatPeso(prestamoCal.balancePeniente)}</span></IonCol>
                      <IonCol sizeXs={"4"} ><span>{Strings.montoRestante}:</span> <span>{formatPeso(prestamoCal.montoRestante)}</span></IonCol>
                  </IonRow>
              </IonGrid>
            <div className={"list"}>
              <div className={"topBtn"}>
                  <div className={!showAmort?"active":""} onClick={()=>setShowAmort(false)}>{Strings.pago}</div>
                  <div className={showAmort?"active":""} onClick={()=>setShowAmort(true)}>{Strings.amortizacion}</div>
              </div>
              {showAmort?(
                  prestamoCal.amort &&  <TableAmort cuotas={prestamoCal.amort} />
              ):(
                  <>
                  <table>
                      <thead>
                      <tr>
                          <th/>
                          <th>{Strings.cuotas}</th>
                          <th>{Strings.fecha}</th>
                          <th>{Strings.monto}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {prestamoCal?.pagos?.map(((p,i)=>
                              <tr key={i} onClick={()=>setPrintRecibo(p)}>
                                  <td className={"btn"}>
                                      {i == 0 &&
                                      <IonButton size={"small"} fill="clear" color={"danger"} onClick={(e)=>(delLastPago(),e.stopPropagation())} >
                                          <IonIcon slot="icon-only" icon={close} />
                                      </IonButton>}
                                  </td>
                                  <td>{p.cuotas.join()}</td>
                                  <td>{formatDate(p.fecha)}</td>
                                  <td>{formatPeso(p.monto)}</td>
                              </tr>
                      ))}
                      </tbody>
                  </table>
                      {printRecibo &&
                          <IonModal isOpen={true} onDidDismiss={()=>setPrintRecibo(undefined)}>
                              <Recibo pago={printRecibo} close={()=>setPrintRecibo(undefined)}/>
                          </IonModal>
                      }
                  </>
              )}
            </div>
              {!!prestamoCal.proximoPago && prestamoCal.amort && addPagoModal &&
                <>
                    <AddPago pre={prestamoCal} onUpdate={(p:IPago)=>(fetch(),setPrintRecibo(p))} onClose={()=>setAddPagoModal(false)}/>
                </>
              }

              <IonButton color="primary" className={"addPaymentBtn"} onClick={()=>setAddPagoModal(!addPagoModal)}>
                  Agregar pago
              </IonButton>


          </>}
      </IonContent>
    </IonPage>
  );
}

export default PrestamoDetalles;
