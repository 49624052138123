import React, {useEffect, useState} from 'react';
import {
    IonButtons, IonButton, IonIcon, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput,
    IonCol, IonRow, IonGrid, IonItem, IonLabel, IonSelect, IonSelectOption, IonDatetime, isPlatform,
    IonMenuButton, IonBackButton, IonModal,
    IonLoading, useIonToast,
    useIonViewWillEnter, useIonViewWillLeave,
} from '@ionic/react';
import {useHistory} from "react-router-dom";

import { File } from '@ionic-native/file';
import { SocialSharing } from '@ionic-native/social-sharing';

import {formatPeso, buildAmort, formatDate, getImgSize} from '../helper/index';
import {CICLOPAGOS,TIPOAMORT} from '../helper/CONSTANTS';

import Chart from "react-apexcharts";

import 'jspdf-autotable'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import Strings from '../assets/strings';

import './calculadora.css';
import {shareSocial, download} from 'ionicons/icons';
import {getAjustes, getPrestamoById, guardarPrestamo} from "../services/localDB";
import {useParams} from "react-router";
import TableAmort from "../components/tableAmort";
import {IPrestamo} from "../interfaces/IPrestamo";


const barOpcion = {
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        // stackType: '100%',
        toolbar: {show: false},
        zoom: {
            type: "x",
            enabled: false,
            autoScaleYaxis: true
        },
    },

    // plotOptions: {
    //   bar: {
    //     horizontal: true,
    //   },
    // },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '100%',
            endingShape: 'flat'
        },
    },
    stroke: {
        show: false,
        width: 0,
        colors: ['#fff']
    },
    xaxis: {
        show: false,
    },
    yaxis: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        y: {
            formatter: (value:any) => formatPeso(value),
        }
    },
    fill: {
        opacity: 1

    },
    legend: {
        show: false
    }
};
const pieOpcion = {
    chart: {
        type: 'donut',
    },
    labels: ["interes","capital"],
    colors: ['#ff8686','#3dc2ff'],
    fill: {
        type: 'gradient',
    },
    dataLabels: {
        enabled: true,
        style: {
            fontSize: '13px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'normal',
            colors: ['#000']

        },
        background: {
            enabled: false,
        },
        dropShadow: {
            enabled: false
        }
    },
    // responsive: [{
    //     breakpoint: 480,
    //     options: {
    //         chart: {
    //             width: 200
    //         },
    //         legend: {
    //             position: 'bottom'
    //         }
    //     }
    // }],
    legend: {
        show: false,
        position: 'right',
        horizontalAlign: 'center',
        offsetX: 40,
        labels: {
            // This more specific font property overrides the global property
            fontColor: 'black'
        }
    },
    tooltip: {
        enabled: false,
        y: {
            formatter: (value:any) => formatPeso(value),
        }
    },

};
const initialState:IPrestamo ={
    monto: 0,
    interes: 0,
    cuotas:0,
    ciclosPago: 30,
    tipoAmortizacion: 1,
    fecha: formatDate(new Date(),"YYYY-MM-DD"),
    cliente:"",
    telefono: "",
    direccion: "",
   // _id:null,
   // _rev:null,
};



const Calculadora: React.FC = () => {
    const history = useHistory();
    const [showLoading, setShowLoading] = useState(false);

    const {preId} = useParams() as {preId:string};
    const [cuotas, setCuotas] = useState<any>([]);
    const [state, setState] = useState(initialState);
    const [showModal, setShowModal] = useState(false);


    const handleChange = (e:any) => {
        const {name, value,type} = e.target;
        setState({...state,[name]: (type === "number") ? parseFloat(value):value})
    };
    const generarAmort = (e:any) =>{
        e?.preventDefault()
        //state.monto,state.interes,state.cuotas,state.ciclosPago,state.tipoAmortizacion,state.fecha
        let amort = buildAmort(state);
        setCuotas(amort)
    }
    useIonViewWillEnter(()=>{
        if(preId){
            setShowLoading(true)
            getPrestamoById(preId).then((res:any)=>{
                let pre = {...res};
                delete pre.id;
                delete pre.rev;
                delete pre.ok;
                setState({...initialState, ...pre})
            }).finally(()=> setShowLoading(false))
        }
    })
    useIonViewWillLeave(()=>{setState(initialState); setCuotas([])})
    let {_id}=state;
    useEffect(()=>{
        if(_id) generarAmort(null);
        // return ()=>setState(initialState)
    },[_id,setState])

    const totalaPagar =  cuotas.map((a:any)=>a.totalaPagar)
    const capital =  cuotas.map((a:any)=>a.capitalApagar)
    const interes =  cuotas.map((a:any)=>a.interes)

    const convertHtml = ()=>{
        //pt 930    = px 1240
        let doc = new jsPDF('p', 'pt');

        getAjustes().then(async (data:any) =>{
                let height = 60;
                let width = 0;

                let imgData = data.avatar

                if(imgData){
                    let ImgSize = await getImgSize(imgData) as {h:number,w:number};
                    width = (height * ImgSize.w) / ImgSize.h;
                    doc.addImage(imgData, 'JPEG', 40, 27,width,height)
                }


                doc.setTextColor(41, 128, 186)
                doc.text( data.empresa,width + 50,45, )
                doc.setTextColor(100)
                doc.setFontSize(10)

                let lineTwo = "";

                if(data.telefono) lineTwo += "Tel: "+data.telefono+" ";
                if(data.celular) lineTwo += "Cel: "+data.celular+" ";
                if(data.rnc) lineTwo += "RNC: "+data.rnc+" ";

                doc.text( lineTwo,width + 50,60)
                doc.text( 'Dir: '+data.direccion,width + 50,73)
            let columns = {
                head: [['#-'+Strings.fecha, Strings.totalApagar, Strings.balance,Strings.abonoCapital,Strings.interes]],
                body: [
                    ...cuotas.map((v:any)=>{
                        return [
                            v.cuota+"-"+v.fecha,
                            formatPeso(v.totalaPagar),
                            formatPeso(v.balanceRestante),
                            formatPeso(v.capitalApagar),
                            formatPeso(v.interes),
                        ]
                    }),
                    [
                        Strings.total+":",
                        formatPeso(totalaPagar.reduce((a: number, b: number) => a + b, 0)),
                        "",
                        formatPeso(capital.reduce((a: number, b: number) => a + b, 0)),
                        formatPeso(interes.reduce((a: number, b: number) => a + b, 0))

                    ]
                ],
            }
            autoTable(doc,{
                styles: { halign: "center" },
                margin: { top: 100 },
                ...columns
            })
            let fileName = 'loanCal-amortizacion.pdf';

            if(isPlatform("desktop")){
                doc.save(fileName);
            }else{
                let blob = doc.output("blob"); //{type: 'application/pdf'}
                File.writeFile(File.cacheDirectory,fileName,blob,{replace: true}).then(()=>{
                    SocialSharing.share(Strings.amortizacion, Strings.amortizacion, File.cacheDirectory + fileName )
                });
            }
        })

    }
    const guardar = () =>{
        if(!state.cliente) return false;
        setShowLoading(true)
        guardarPrestamo(state).then((res:any)=>{
            setState({...state,_rev: res.rev})
            setShowModal(false);
            history.replace("/prestamoDetalle/"+res.id)
        }).finally(()=> setShowLoading(false))
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        {!!preId ? (
                            <IonBackButton/>
                        ):(
                            <IonMenuButton />
                        )}
                    </IonButtons>
                    {!!cuotas.length &&
                    <IonButtons slot="primary">
                        <IonButton onClick={convertHtml} >
                            <IonIcon slot="icon-only" icon={isPlatform("desktop")? download :shareSocial}/>
                        </IonButton>
                    </IonButtons>
                    }
                    <IonTitle>LoanCal</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonLoading isOpen={showLoading} message={Strings.cargando}/>
                    <form onSubmit={generarAmort}>
                        <IonGrid>
                            <IonRow>
                                {!!state._id &&
                                <IonCol sizeXs={"12"}>
                                    <IonItem>
                                        <IonLabel position="floating">{Strings.cliente}</IonLabel>
                                        <IonInput value={state.cliente} name={"cliente"} onIonChange={handleChange} required/>
                                    </IonItem>
                                </IonCol>
                                }
                                <IonCol sizeXs={"6"} sizeSm="4">
                                    <IonItem>
                                        <IonLabel position="floating">{Strings.monto} {Strings.capital}</IonLabel>
                                        <IonInput type={"number"} step={"any"} inputmode="decimal" value={state.monto} name={"monto"} onIonChange={handleChange} required></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs={"6"} sizeSm="4">
                                    <IonItem>
                                        <IonLabel position="floating">{Strings.modalidad}</IonLabel>
                                        <IonSelect interface="popover" value={state.ciclosPago} name={"ciclosPago"} onIonChange={handleChange} >
                                            {CICLOPAGOS.map(e=>
                                                <IonSelectOption value={e.key} key={e.key}>{(Strings as any)[e.value]}</IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs={"6"} sizeSm="4">
                                    <IonItem>
                                        <IonLabel position="floating">% {Strings.interes} {CICLOPAGOS.find(e=>e.key === state.ciclosPago)?.value.toLocaleLowerCase()}</IonLabel>
                                        <IonInput type={"number"} step="any" inputmode="decimal" value={state.interes}
                                                  name={"interes"} onIonChange={handleChange} required/>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs={"6"} sizeSm="4">
                                    <IonItem>
                                        <IonLabel position="floating">{Strings.amortizacion}</IonLabel>
                                        <IonSelect interface="popover" value={state.tipoAmortizacion} name={"tipoAmortizacion"} onIonChange={handleChange}>
                                            {TIPOAMORT.map(e=>
                                                <IonSelectOption value={e.key} key={e.key}>{(Strings as any)[e.value]}</IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs={"6"} sizeSm="4">
                                    <IonItem>
                                        <IonLabel position="floating">{Strings.cuotas}</IonLabel>
                                        <IonInput type={"number"} value={state.cuotas} name={"cuotas"} onIonChange={handleChange} required></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs={"6"} sizeSm="4">
                                    <IonItem>
                                        <IonLabel position="floating">{Strings.primerPago}</IonLabel>
                                        <IonDatetime displayFormat="MM/DD/YYYY" style={{paddingBottom: 5}}
                                                     min={"2020-01-01"}
                                                     max="2026-12-12"
                                                     value={formatDate(state.fecha,"MM-DD-YYYY")}
                                                     name={"fecha"}  onIonChange={handleChange}
                                                     cancelText={Strings.cancelar}
                                                     doneText={"Ok"}
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton type={"submit"} expand="full" color={"primary"}>{Strings.calcular}</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </form>

                {!!cuotas.length &&
                <>
                    <div className={"totalGraf"}>
                        {/*<div className={"total"}>{Strings.totalApagar}: <span>{formatPeso(totalaPagar.reduce((a: number, b: number) => a + b, 0))}</span></div>*/}
                        <IonGrid>
                            <IonRow>
                                <IonCol sizeXs="6">
                                    <Chart options={{...pieOpcion}} series={[
                                        interes.reduce((a: number, b: number) => a + b, 0),
                                        capital.reduce((a: number, b: number) => a + b, 0)]}
                                           type="donut"
                                           height={138}
                                    />
                                </IonCol>
                                <IonCol sizeXs="6">
                                    <div className={"detalles"}>
                                        <div>
                                            <div>{formatPeso(interes.reduce((a: number, b: number) => a + b, 0))}</div>
                                            <label>{Strings.total} {Strings.interes}</label>
                                        </div>
                                        <div>
                                            <div>{formatPeso(capital.reduce((a: number, b: number) => a + b, 0))}</div>
                                            <label>{Strings.total} {Strings.capital}</label>
                                        </div>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        {/*<Chart options={{...barOpcion}} series={[{ name: 'Interés', data:interes }, { name: 'Capital', data: capital }]} type="bar" height={250} />*/}
                    </div>
                    <div className={"amortCont"} >
                        <div className={"header"}>
                            <div>
                                {Strings.cronograma}
                            </div>
                            <IonButton  fill={"clear"} color={"secondary"} onClick={()=>setShowModal(true)}>{Strings.guardar}</IonButton>

                        </div>
                        <TableAmort cuotas={cuotas} />
                    </div>
                    <IonModal isOpen={showModal} >
                        <IonGrid>
                            <IonRow>
                                <IonCol sizeXs={"12"}>
                                    <IonItem>
                                        <IonLabel position="floating">{Strings.cliente}</IonLabel>
                                        <IonInput value={state.cliente} name={"cliente"} onIonChange={handleChange} required></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs={"12"}>
                                    <IonItem>
                                        <IonLabel position="floating">{Strings.telefono}</IonLabel>
                                        <IonInput value={state.telefono} name={"telefono"} onIonChange={handleChange}></IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol sizeXs={"12"}>
                                    <IonItem>
                                        <IonLabel position="floating">{Strings.direccion}</IonLabel>
                                        <IonInput value={state.direccion} name={"direccion"} onIonChange={handleChange} ></IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonButton onClick={guardar} color={"secondary"}>{Strings.guardar}</IonButton>

                    </IonModal>
                </>
                }



            </IonContent>
        </IonPage>
    );
}

export default Calculadora;
