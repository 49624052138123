import {
  IonAvatar,
  IonContent, IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle, IonToolbar,
  IonModal, IonButton, isPlatform
} from '@ionic/react';
import { useLocation } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';

import {helpCircleOutline, calculatorOutline, settingsOutline,starOutline,shareSocialOutline, documentTextOutline,cashOutline} from 'ionicons/icons';
import './Menu.css';
import React, {useEffect, useState} from "react";
import {SocialSharing} from "@ionic-native/social-sharing";
import {getAjustes} from "../services/localDB";
import Strings from '../assets/strings';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'calculadora',
    url: '/calculadora',
    iosIcon: calculatorOutline,
    mdIcon: calculatorOutline
  },
  {
    title: 'prestamos',
    url: '/prestamos',
    iosIcon: documentTextOutline,
    mdIcon: documentTextOutline
  },
  {
    title: 'pagos',
    url: '/pagos',
    iosIcon: cashOutline,
    mdIcon: cashOutline
  },
  {
    title: 'ajustes',
    url: '/ajustes',
    iosIcon: settingsOutline,
    mdIcon: settingsOutline
  },
  {
    title: 'sobreNosotros',
    url: '/about',
    iosIcon: helpCircleOutline,
    mdIcon: helpCircleOutline
  }

];


const initialState={
  empresa: "",
  avatar:""
};
const Menu: React.FC = () => {
  const location = useLocation();
  const [data, setData] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const [rate, setRate] = useState(0);


  useEffect(()=>{
    getAjustes().then((res:any)=>
        setData({...initialState,...res})
    )


    // let loanCal = localStorage.getItem('loanCal');
    // if(loanCal){
    //   setData({...initialState,...JSON.parse(loanCal)})
    // }
  },[])
  const urlApp = "https://play.google.com/store/apps/details?id=com.dsegura.loancal";

  const shareApp = ()=>{
    const options = {
      message : "loabCal: calculadora de préstamos",
      url:urlApp
    }
    if(isPlatform("hybrid")){
      SocialSharing.shareWithOptions(options)
    }else {
      window.open(options.url,"_Blank")
    }
  }
  return (
      <>
    <IonMenu contentId="main" type="overlay">
      {(data.empresa)?(
          <IonHeader>
            <IonToolbar color={"primary"}>
              <IonItem color={"primary"}>
                {data.avatar &&
                <IonAvatar slot="start">
                  <img src={data.avatar} />
                </IonAvatar>}
                <IonLabel>{data.empresa}</IonLabel>
              </IonItem>
            </IonToolbar>
          </IonHeader>
        ):(<></>)
      }
      <IonContent>
        <IonList id="inbox-list">
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{(Strings as any)[appPage.title]}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        {!isPlatform("ios") &&
          <IonList>
            <IonMenuToggle autoHide={false}>
              <IonItem lines="none" button  onClick={() => setShowModal(true)}>
                <IonIcon slot="start" icon={starOutline} />
                <IonLabel>{Strings.calificanos}</IonLabel>
              </IonItem>
            </IonMenuToggle>
            <IonMenuToggle autoHide={false}>
              <IonItem lines="none" button  onClick={shareApp}>
                <IonIcon slot="start" icon={shareSocialOutline} />
                <IonLabel>{Strings.compartirApp}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        }

        <IonModal isOpen={showModal}
                  onDidDismiss={() => setShowModal(false)}
                  cssClass='modal'>
          <p>{Strings.motivaMsj}</p>
          <div className={"rateCont"}>
            <StarRatingComponent
                name="rate1"
                starCount={5}
                value={rate}
                onStarClick={(v)=>setRate(v)}
            />
          </div>
          <div className={"rateBtn"}>
            <IonButton onClick={() =>
                (rate > 3) ? window.open(urlApp,"_Blank") : setShowModal(false)
            } expand={"block"}>{Strings.calificanos}</IonButton>
            <IonButton color="light" size={"small"} onClick={() => setShowModal(false)}>No, Gracias</IonButton>
          </div>
        </IonModal>
      </IonContent>
    </IonMenu>

      </>
  );
};

export default Menu;
