
import './style.css';
import React from "react";

import Strings from '../../assets/strings';
import {formatDate, formatPeso} from "../../helper";
import {IAmort} from "../../interfaces/IAmort";


function TableAmort({cuotas}:{cuotas:IAmort[]}){

  return (
      <div className="amortTable">
        <table>
          <thead>
          <tr className="headerRow ion-nowrap" style={{flexWrap: "nowrap"}}>
            <th >
              #
            </th>
            <th >
              {Strings.fecha}
            </th>
            <th >
              {Strings.cuotas}
            </th>
            <th >
              {Strings.capital}
            </th>
            <th >
              {Strings.interes}
            </th>
            <th >
              {Strings.balance}
            </th>
          </tr>
          </thead>
          <tbody>
          {cuotas.map((a:any,i:number)=> {
            let bar = (a.capitalApagar * 100) / a.totalaPagar;
            return(
                <tr key={i} className="ion-nowrap" style={{flexWrap: "nowrap"}}>
                  <td>
                    {a.cuota}
                  </td>
                  <td>
                    {formatDate(a.fecha)}
                  </td>
                  <td >
                    {formatPeso(a.totalaPagar)}
                  </td>
                  <td colSpan={2} className={"combo"}>
                    <div className={"top"}>
                      <span>{formatPeso(a.capitalApagar)}</span>
                      <span>{formatPeso(a.interes)}</span>
                    </div>

                    <div className={"bar"}>
                      <span style={{width: bar+"%"}}></span>
                    </div>
                    {/*<div className={"monto"}>Monto: {formatPeso(a.totalaPagar)}</div>*/}
                  </td>
                  <td>
                    {formatPeso(a.balanceRestante)}
                  </td>
                </tr>
            )
          })}
          </tbody>
        </table>
      </div>
  );
};

export default TableAmort;
