import React, {useState, useRef, useEffect, useCallback} from 'react';
import {
    IonButtons, IonModal, IonToast, IonButton, IonContent, IonHeader,
    IonTextarea, IonPage, IonIcon, IonTitle, IonToolbar, IonInput, IonCol, IonRow, IonGrid, IonItem,
    IonLabel, IonMenuButton, IonSelect, IonSelectOption, IonLoading
} from '@ionic/react';
import {imagesOutline } from 'ionicons/icons';

import 'jspdf-autotable'
import './ajustes.css';
import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import Strings from '../assets/strings';
import Paises from '../helper/Paises.json';

import {setLSLang,getLSLang} from '../helper';
import {getAjustes, updateAjustes} from "../services/localDB";
const initialState={
    lang:getLSLang("l"),
    moneda:getLSLang("c"),
    empresa: "",
    telefono: "",
    celular:"",
    rnc: "",
    direccion: "",
    avatar:"",
    _id:null,
    _rev:null,
};


const Ajustes: React.FC = () => {
    const [state, setState] = useState(initialState);
    const [showLoading, setShowLoading] = useState(false);

    const [crop, setCrop] = useState();

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const [toCrop, setToCrop] = useState("");
    const [msj, setMsj] = useState("");
    const [showAddForm, setShowAddForm] = useState(false);
    const [completedCrop, setCompletedCrop] = useState<any>(null);
    useEffect(()=>{
        setShowLoading(true)
        getAjustes().then((res:any)=>
            setState({...initialState,...res})
        ).finally(()=> setShowLoading(false))
        // let loanCal = localStorage.getItem('loanCal');
        // localStorage.getItem('loanCal');
        // if(loanCal){
        //     setState({...initialState,...JSON.parse(loanCal)})
        // }

    },[setShowLoading])


    const handleChange = (e:any) => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const onSave = () => {
        let data = {...state}
        setShowLoading(true)
        updateAjustes(data).then((res:any)=> {
            setState({...state, _rev: res.rev})
            setMsj(Strings.guardadoMsj)
        }).finally(()=> setShowLoading(false))

        // let data = {...state}
        // localStorage.setItem('loanCal', JSON.stringify(data));
        // setMsj(Strings.guardadoMsj)
    };
    const showModal = () => {
        setShowAddForm(true)
    }
    const closeModal = () => {
        setShowAddForm(false)
    }
    const handleChangeImg = (e:any) => {
        e.preventDefault();
        setMsj("");
        let file:any;
        if (e.dataTransfer) {
            file = e.dataTransfer.files[0];
        } else if (e.target) {
            file = e.target.files[0];
        }
        if (!file) return false;
        if(file.type.indexOf("image") === -1 && file.type.indexOf("pdf") === -1) return setMsj(Strings.archivoNoPermitidoMsj)

        if(file.type.indexOf("pdf") !== -1 && file.size > 1000000) return setMsj(Strings.archivoExcedeMsj+", 1MB!");


        const reader = new FileReader();
        reader.onload = () => {
            if(file.type.indexOf("image") !== -1){
                 setToCrop(reader.result as string);
                 showModal()
            }else{
               // onChange(reader.result);
            }
            //    e.target.files = [];
        };
        reader.readAsDataURL(file);

    }
    const onLoad = useCallback((toCrop) => {
        imgRef.current = toCrop;
    }, []);
    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image:any = imgRef.current;
        const canvas:any = previewCanvasRef.current;
        const crop = completedCrop;

        if(image && canvas){
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            const ctx = canvas.getContext('2d');
            const pixelRatio = window.devicePixelRatio;

            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;

            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
        }

    }, [completedCrop]);
    const setImgCrop = () => {
        if (!previewCanvasRef.current) {
            return;
        }

        const canvas:any = previewCanvasRef.current;
        let base64 = canvas.toDataURL();

        if(base64.length > 100){
            setState({...state,avatar:base64})
        }

        closeModal()
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonButtons slot="primary">
                        <IonButton onClick={onSave} >
                            {Strings.guardar}
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{Strings.ajustes}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonLoading isOpen={showLoading} message={Strings.cargando}/>

                <form>
                    <div className={"avatar"}>
                        <label>
                            <input type="file" style={{display:"none"}} accept="image/gif, image/jpeg, image/png" onChange={handleChangeImg}/>
                            {state.avatar ? (
                                <img src={state.avatar} alt="" />)
                                :(
                                    <IonIcon src={imagesOutline} className={"defaultAvatar"}/>
                                )}
                        </label>
                    </div>
                    <IonGrid>
                        <IonRow>
                            <IonCol sizeXs="12" sizeSm="6">
                                <IonItem>
                                    <IonLabel position="floating">{Strings.empresa}</IonLabel>
                                    <IonInput value={state.empresa} name={"empresa"} onIonChange={handleChange} required></IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="6">
                                <IonItem>
                                    <IonLabel position="floating">{Strings.telefono}</IonLabel>
                                    <IonInput value={state.telefono} name={"telefono"} onIonChange={handleChange} required></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="12" sizeSm="6">
                                <IonItem>
                                    <IonLabel position="floating">{Strings.celular}</IonLabel>
                                    <IonInput value={state.celular} name={"celular"} onIonChange={handleChange}></IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="6">
                                <IonItem>
                                    <IonLabel position="floating">{Strings.rnc}</IonLabel>
                                    <IonInput value={state.rnc} name={"rnc"} onIonChange={handleChange}></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="12">
                                <IonItem>
                                    <IonLabel position="floating">{Strings.direccion}</IonLabel>
                                    <IonTextarea  value={state.direccion} name={"direccion"}  onIonChange={handleChange} required></IonTextarea >
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <IonCol sizeXs="12" sizeSm="6">
                                <IonItem>
                                    <IonLabel position="floating">{Strings.idioma}</IonLabel>
                                    <IonSelect interface="popover" value={state.lang} name={"lang"} onIonChange={(e:any)=>{
                                        const {value:lang} = e.target;
                                        setLSLang(lang);
                                        Strings.setLanguage(lang);
                                        setState({...state,lang});
                                    }}>
                                        {Strings.getAvailableLanguages().map(l=>
                                            <IonSelectOption value={l} key={l}>{(Strings as any)[l]}</IonSelectOption>
                                        )}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXs="12" sizeSm="6">
                                <IonItem>
                                    <IonLabel position="floating">{Strings.moneda}</IonLabel>
                                    <IonSelect interface="popover" value={state.moneda} name={"lang"} onIonChange={(e:any)=>{
                                        const {value:moneda} = e.target;
                                        setLSLang("",moneda);
                                        setState({...state,moneda});
                                    }}>
                                        <IonSelectOption value={""}>{Strings.seleccione}</IonSelectOption>
                                        {Paises.map(p=>
                                            <IonSelectOption value={p.paiCodigo} key={p.paiCodigo}>{p.paiNacionalidad}</IonSelectOption>
                                        )}
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
                <IonModal isOpen={showAddForm} onDidDismiss={closeModal}>
                    <ReactCrop src={toCrop} crop={crop} onChange={(newCrop:any) => setCrop(newCrop)}
                               onImageLoaded={onLoad}
                               onComplete={(c:any) => setCompletedCrop(c)}
                    />

                    <div style={{display:"none"}}>
                        <canvas
                            ref={previewCanvasRef}
                            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                            style={{
                                width: Math.round(completedCrop?.width ?? 0),
                                height: Math.round(completedCrop?.height ?? 0)
                            }}
                        />
                    </div>
                    <IonButton onClick={setImgCrop}>{Strings.cortar}</IonButton>
                </IonModal>
                <IonToast
                    isOpen={!!msj}
                    onDidDismiss={() => setMsj("")}
                    message={msj}
                    duration={1500}
                />

            </IonContent>
        </IonPage>
    );
};

export default Ajustes;
